import React from "react";
import { useNavigate } from "react-router-dom";

import RouterLink from "components/partials/router-link/router-link";
import { TableViewButton } from "components/table/table-icon-button/table-icon-button";

import Campaign from "models/campaign";

import { useGetProgramTypesQuery, getDictionaryItemDescription } from "state/api/dictionary";
import { TableRow } from "components/table/table";
import { TableCell } from "components/table/table-cell/table-cell";
import { LastUpdatedColumn } from "components/table/table-columns/table-columns";

interface CampaignRowProps {
  campaign: Campaign;
}

const CampaignRow: React.FC<CampaignRowProps> = ({ campaign }) => {
  const navigate = useNavigate();
  const { data: programTypes = [] } = useGetProgramTypesQuery();

  const campaignCreativeRoute = `${campaign.id}/creative`;

  return (
    <TableRow>
      <TableCell>
        <RouterLink to={campaignCreativeRoute}>{campaign.name}</RouterLink>
      </TableCell>
      <TableCell>{getDictionaryItemDescription(programTypes, campaign.programType)}</TableCell>
      <LastUpdatedColumn lastModifiedDate={campaign.lastModifiedDate} />
      <TableCell>
        <div className="flex">
          <TableViewButton
            title="View campaign"
            aria-label="View campaign"
            onClick={() => navigate(campaignCreativeRoute)}
          />
        </div>
      </TableCell>
    </TableRow>
  );
};

export default CampaignRow;
