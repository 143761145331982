import { Content } from "components/partials/layout/layout";

const VersionHistory = () => {
  return (
    <Content pb={4} pt={4}>
      Version History
    </Content>
  );
};

export default VersionHistory;
