import { useContext } from "react";

import { ThemeContext } from "containers/admin/clients/client/theme-page/preview/preview";

const PreviewArea = () => {
  const {
    colors: { primary, secondary, tertiary, intense, bold, soft, subtle, white },
  } = useContext(ThemeContext);

  return (
    <div className="flex-1 overflow-x-auto pl-6 py-5 pr-8">
      <p style={{ color: primary }}>Primary input value is applied here...</p>
      <p style={{ color: secondary }}>Secondary input value is applied here...</p>
      <p style={{ color: tertiary }}>Tertiary input value is applied here...</p>
      <p style={{ color: intense }}>Intense input value is applied here...</p>
      <p style={{ color: bold }}>Bold input value is applied here...</p>
      <p style={{ color: soft }}>Soft input value is applied here...</p>
      <p style={{ color: subtle }}>Subtle input value is applied here...</p>
      <p style={{ color: white }}>White input value is applied here...</p>
    </div>
  );
};

export default PreviewArea;
