import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDisclosure } from "@chakra-ui/react";

import EmailForm from "containers/admin/clients/touchpoint/components/email-form/email-form";
import PageContentHeader from "components/partials/page-content-header/page-content-header";
import { H2 } from "components/partials/typography/typography";
import VersionEditModal, {
  FormType,
  onSubmitFormProps,
} from "components/modals/version-edit-modal/version-edit-modal";
import toast from "components/partials/toast/toast";
import { useCurrentClient, useCurrentCampaign } from "state/ducks";
import Touchpoint, { TouchpointAttributes } from "models/touchpoint";
import { Unpersisted } from "models/model";
import TouchpointVersion from "models/touchpoint-version";

import { getErrorMessage } from "utilities";

import { usePagePermission } from "hooks/use-page-permission";
import { usePermissionBlacklist } from "hooks/use-permission-blacklist";

import { Permission } from "types/auth";

export type createTouchpointActionType = "toBuilder" | "toTouchpoint";

const EmailNewPage = () => {
  const navigate = useNavigate();
  const currentClient = useCurrentClient();
  const currentCampaign = useCurrentCampaign();
  const createNewDraftModal = useDisclosure();
  const [newEmailData, setNewEmailData] = useState<Touchpoint>();

  usePermissionBlacklist([Permission.PERM_CLIENT_USER]);
  usePagePermission(Permission.PERM_TOUCHPOINT_WRITE);

  const createEmail = (
    attributes: Unpersisted<TouchpointAttributes>,
    actionType: createTouchpointActionType,
  ) => {
    Touchpoint.create({
      clientId: currentClient.id,
      campaignId: currentCampaign.id,
      attributes: attributes,
    })
      .then((results) => {
        if (actionType === "toTouchpoint") {
          toast.success({
            data: {
              title: "Email created",
            },
          });
          navigate(".."); // Navigate up one level back to creative page
        }
        if (actionType === "toBuilder") {
          setNewEmailData(results);
          createNewDraftModal.onOpen();
        }
      })
      .catch((err) => {
        toast.error({
          data: {
            title: "Failed to create Email",
            message: getErrorMessage(err?.response?.data),
          },
        });
      });
  };

  const createDraftSubmit = ({ version, versionNotes, visibleToClient }: onSubmitFormProps) => {
    newEmailData &&
      TouchpointVersion.create({
        campaignId: currentCampaign.id,
        clientId: currentClient.id,
        touchpointId: newEmailData.id,
        version: version,
        versionNotes: versionNotes,
        visibleToClient: visibleToClient,
      }).then((response) => {
        toast.success({
          data: {
            title: "Email created",
          },
        });
        navigate(`../${newEmailData.id}/versions/${response.id}/email-builder`);
      });
  };

  const cancelCreateEmail = () => {
    // Redirect to touchpoints page
    navigate("..");
  };

  return (
    <>
      <PageContentHeader>
        <H2 className="pt-5">Add New Email</H2>
      </PageContentHeader>
      <EmailForm onSubmit={createEmail} onCancel={cancelCreateEmail} />
      <VersionEditModal
        {...createNewDraftModal}
        parentName={currentCampaign.name}
        name={newEmailData?.name}
        formType={FormType.NEW}
        onSubmit={createDraftSubmit}
        version="1.0"
        placeholderNotes="Initial draft"
      />
    </>
  );
};

export default EmailNewPage;
