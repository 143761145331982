import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import Color from "containers/admin/clients/client/theme-page/preview/sub-tabs/color/color";
import Fonts from "containers/admin/clients/client/theme-page/preview/sub-tabs/fonts/fonts";
import Typography from "containers/admin/clients/client/theme-page/preview/sub-tabs/typography/typography";
import Button from "containers/admin/clients/client/theme-page/preview/sub-tabs/button/button";

import { PreviewRoute } from "utilities/app-routes";

const PreviewRoutes = () => {
  const { pathname } = useLocation();

  return (
    <Routes>
      <Route index element={<Navigate to={pathname + PreviewRoute.color} />} />

      <Route path={PreviewRoute.color} element={<Color />} />
      <Route path={PreviewRoute.fonts} element={<Fonts />} />
      <Route path={PreviewRoute.typography} element={<Typography />} />
      <Route path={PreviewRoute.button} element={<Button />} />
    </Routes>
  );
};

export default PreviewRoutes;
