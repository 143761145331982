import { createContext, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";

import ToggleButtonGroup from "components/partials/toggle-button-group/toggle-button-group";
import { mapToggleOptions } from "components/partials/toggle-button-group/mapToggleOptions";
import PreviewRoutes from "containers/admin/clients/client/theme-page/preview/preview-routes";

import { TabItem, useTab } from "hooks/use-tab";

import { getThemePreviewTabRoute } from "containers/admin/clients/client/theme-page/theme-page.helper";
import { Route as AppRoute } from "utilities/app-routes";

import {
  PreviewFormData,
  ColorsTabFormData,
  ThemeContextData,
} from "containers/admin/clients/client/theme-page/preview/preview.types";

import {
  PREVIEW_FORM_DATA_VALIDATION_SCHEMA,
  PREVIEW_FORM_DEFAULT_VALUES,
} from "containers/admin/clients/client/theme-page/preview/preview.constants";

const THEME_PREVIEW_SUBTABS: TabItem[] = [
  { name: "Colors", path: "colors" },
  { name: "Fonts", path: "fonts" },
  { name: "Typography", path: "typography" },
  { name: "Button", path: "button" },
] as const;

export const ThemeContext = createContext<ThemeContextData>({
  colors: PREVIEW_FORM_DEFAULT_VALUES.colors,
  setColors: () => {},
});

const Preview = () => {
  const { clientId } = useParams();
  const navigate = useNavigate();

  //tabs settings
  const currentSubTab = useTab(THEME_PREVIEW_SUBTABS, "Color");

  const changeSubTab = (tabName: string) => {
    const basePath = `${AppRoute.clients}/${clientId}/${AppRoute.theme}/${AppRoute.preview}`;

    const tab = getThemePreviewTabRoute(tabName, basePath);
    navigate(tab, { replace: true });
  };

  //set tabs values for context
  const [colors, setColors] = useState<ColorsTabFormData>(PREVIEW_FORM_DEFAULT_VALUES.colors);

  //form
  const methods = useForm<PreviewFormData>({
    mode: "onChange",
    defaultValues: PREVIEW_FORM_DEFAULT_VALUES,
    resolver: PREVIEW_FORM_DATA_VALIDATION_SCHEMA,
    shouldFocusError: true,
  });

  const onSubmit = (values: PreviewFormData) => {
    console.log(values);
  };

  const tabHasError = (tabPath: string) => {
    return Object.keys(methods.formState.errors).some(
      (key) => key.toLowerCase() === tabPath.toLowerCase(),
    );
  };

  return (
    <FormProvider {...methods}>
      <ThemeContext.Provider value={{ colors, setColors }}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className="flex flex-1 flex-col pb-14 overflow-hidden h-full">
          <div className="flex px-8 py-3 justify-between items-center">
            <ToggleButtonGroup
              groupName="theme-preview-tabs-toggle"
              options={mapToggleOptions({
                tabs: THEME_PREVIEW_SUBTABS,
                shouldShowIcon: tabHasError,
              })}
              onChange={(option) => changeSubTab(option)}
              value={currentSubTab}
            />
            <button type="submit">Save</button>
          </div>
          <div className="border-t border-zinc-200 flex flex-1 overflow-auto">
            <PreviewRoutes />
          </div>
        </form>
      </ThemeContext.Provider>
    </FormProvider>
  );
};

export default Preview;
