import { useEffect } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ApolloSelect } from "@marketview/lunar-components";

import ThemeRoutes from "containers/admin/clients/client/theme-page/theme-page-routes";

import { H2 } from "components/partials/typography/typography";
import ToggleButtonGroup from "components/partials/toggle-button-group/toggle-button-group";
import { mapToggleOptions } from "components/partials/toggle-button-group/mapToggleOptions";

import { setCurrentTheme } from "state/ducks/themes";

import { TabItem, useTab } from "hooks/use-tab";

import { getThemeTabRoute } from "containers/admin/clients/client/theme-page/theme-page.helper";
import { Route as AppRoute } from "utilities/app-routes";

import { THEME_MOCK_DATA, MOCKED_VERSIONS } from "./theme-page-mock-data";

import Icons from "assets";

const THEME_MAIN_TABS: TabItem[] = [
  { name: "Preview", path: "preview" },
  { name: "Version History", path: "versions" },
] as const;

const ThemePage = () => {
  const { clientId } = useParams();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const currentTab = useTab(THEME_MAIN_TABS, "Preview");

  const changeTab = (tabName: string) => {
    const basePath = `${AppRoute.clients}/${clientId}/${AppRoute.theme}/`;

    const tab = getThemeTabRoute(tabName, basePath);
    navigate(tab, { replace: true });
  };

  useEffect(() => {
    return () => {
      dispatch(setCurrentTheme(THEME_MOCK_DATA));
    };
    // eslint-disable-next-line
  }, []);

  // TODO: Update with real logic
  const previewIsLocked = (tabPath: string) => {
    // return themeIsPublished && tabPath === "preview";
    return false
  };

  return (
    <>
      <div className="flex flex-col bg-neutral-4 px-8 pt-4">
        <div className="flex justify-between items-center mb-2">
          <H2 className="!text-2xl !leading-[30px]">Theme Configuration</H2>
          <div className="w-56">
            <ApolloSelect
              ariaLabel="Select a theme version"
              name="theme-versions-select"
              size="md"
              defaultValue={MOCKED_VERSIONS[0]}
              options={MOCKED_VERSIONS}
              onChange={() => {
                currentTab !== "Preview" && changeTab("Preview");
              }}
            />
          </div>
        </div>
        <ToggleButtonGroup
          variant="primary"
          groupName="theme-tabs-toggle"
          options={mapToggleOptions({
            tabs: THEME_MAIN_TABS,
            shouldShowIcon: previewIsLocked,
            Icon: <Icons.LockClosed />,
          })}
          onChange={(tabName) => changeTab(tabName)}
          value={currentTab}
        />
      </div>
      <ThemeRoutes />
    </>
  );
};

export default ThemePage;
