import uniqid from "uniqid";

import HexInput from "containers/admin/clients/client/theme-page/preview/components/hex-input/hex-input";

import { PreviewSidebarInputType } from "containers/admin/clients/client/theme-page/preview/preview-sidebar/preview-sidebar.types";

const ColorsSectionContent = ({ content }: { content: PreviewSidebarInputType[] }) => {
  return (
    <div className="flex flex-col gap-5">
      {content.map((el: PreviewSidebarInputType) => (
        <HexInput {...el} key={uniqid()} />
      ))}
    </div>
  );
};

export default ColorsSectionContent;
