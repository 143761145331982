import { useState, useRef, useContext } from "react";
import { Controller, useFormContext, FieldError } from "react-hook-form";
import clsx from "clsx";

import { ThemeContext } from "containers/admin/clients/client/theme-page/preview/preview";

import { PreviewSidebarInputType } from "containers/admin/clients/client/theme-page/preview/preview-sidebar/preview-sidebar.types";
import { ColorsTabFormData } from "containers/admin/clients/client/theme-page/preview/preview.types";

import Icons from "assets";

const HexInput = ({ name, label, isRequired, isDisabled = false }: PreviewSidebarInputType) => {
  const { setColors } = useContext(ThemeContext);

  const [isPickerVisible, setPickerVisible] = useState(false);

  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleFocus = () => {
    setPickerVisible(true);
  };

  const handleBlur = (event: React.FocusEvent<HTMLDivElement>) => {
    const currentTarget = event.currentTarget;

    // Delay to allow focus to shift to another child input before checking if we should close
    setTimeout(() => {
      if (!currentTarget.contains(document.activeElement)) {
        setPickerVisible(false);
      }
    }, 0);
  };

  //form
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const colorsErrors = errors?.colors as Record<string, FieldError>;

  const handleChange = (value: string, fieldOnChange: (value: string) => void) => {
    setColors((prev: ColorsTabFormData) => ({
      ...prev,
      [name]: value,
    }));
    fieldOnChange(value);
  };

  //styles
  const hexInputStyles = clsx(
    "flex gap-2.5 rounded-md bg-white border border-zinc-200 px-2.5 py-1 text-base font-normal leading-6",
    {
      "!border-red-600": colorsErrors && colorsErrors[name],
      "!border-border-disabled bg-zinc-200": isDisabled,
    },
  );
  const colorPickerInputStyles = "bg-transparent !border-none rounded-full size-7";
  const textInputStyles = "text-base cursor-default focus:outline-none w-full";

  return (
    <Controller
      name={`colors.${name}`}
      control={control}
      render={({ field }) => (
        <div
          className="flex flex-col gap-2"
          onFocus={handleFocus}
          onBlur={handleBlur}
          ref={wrapperRef}>
          <div className="flex flex-wrap">
            <label
              htmlFor={name}
              id={`${name}-label`}
              className="w-full text-base font-semibold text-text-label">
              {label}
              {!isDisabled && isRequired ? (
                <span>
                  <Icons.RequiredIndicator customClass="inline-block align-top text-text-indicator-required w-[7px] h-[7px] ml-1 mt-1" />
                </span>
              ) : null}
            </label>
          </div>
          <div className={hexInputStyles}>
            {isPickerVisible || field.value ? (
              <input
                type="color"
                data-testid="color-hex-picker"
                className={colorPickerInputStyles}
                {...field}
                onChange={(e) => {
                  handleChange(e.target.value, field.onChange);
                }}
              />
            ) : (
              <div
                data-testid="svg-icon"
                className="bg-transparent cursor-pointer size-7 flex items-center justify-center"
                onClick={() => setPickerVisible(true)}>
                <Icons.ChooseColor />
              </div>
            )}
            <input
              type="text"
              data-testid="color-text-input"
              className={textInputStyles}
              placeholder="Type hex code..."
              maxLength={7}
              {...field}
              onFocus={(e) => {
                if (!field.value.startsWith("#")) {
                  const value = `#${field.value}`;
                  handleChange(value, field.onChange);
                }
              }}
              onChange={(e) => {
                const value = !e.target.value.startsWith("#")
                  ? `#${e.target.value.replace(/#/g, "")}`
                  : e.target.value;

                handleChange(value, field.onChange);
              }}
              value={field.value || ""}
            />
          </div>
          {!!colorsErrors?.[name] && (
            <div className="flex items-center">
              <Icons.InfoIndicator className="text-red-600 size-5 flex items-center justify-center" />
              <span className="text-red-600 ml-1 text-sm">
                <div>{colorsErrors[name].message}</div>
              </span>
            </div>
          )}
        </div>
      )}
    />
  );
};

export default HexInput;
