import { Navigate, Route, Routes } from "react-router-dom";
import { FullStory } from "@fullstory/browser";

// Admin
import ClientsRoutes from "containers/admin/clients/clients-routes";
import InboxPage from "containers/admin/inbox/inbox";
// Client
import ReviewsPage from "containers/client/reviews/reviews";
// Common
import UIKitPage from "containers/ui-kit-page/ui-kit-page";
import NotFoundPage from "containers/not-found-page/not-found-page";

import AppHeader from "components/partials/header/header";
import Logout from "components/partials/logout/logout";

import { useCurrentUser } from "state/ducks";

import useSetPageTitle from "hooks/use-set-page-title";
import { hasPermission } from "utilities/user";

import { Permission } from "types/auth";

// TODO: All the routes need to be here NOT through out the app in different modules
const AuthenticatedApp = () => {
  const currentUser = useCurrentUser();

  const isClientUser = hasPermission(currentUser, Permission.PERM_CLIENT_USER);

  // Set site title from Two Ocean to Apollo after login, if !isClientUser.
  // TODO: Append page title, like useSetPageTitle({ isClientUser, pageTitle: "Inbox" })
  useSetPageTitle({ isClientUser });

  FullStory("setIdentity", {
    uid: currentUser.id,
    properties: {
      email: currentUser.email,
    },
  });

  const redirectRoutes = ["/", "/login"];

  if (isClientUser) {
    // prevent client users from accessing the clients page
    redirectRoutes.push("/clients");
  }

  const entryRoute = isClientUser ? "/reviews" : "/clients";

  return (
    <>
      <AppHeader />
      <Routes>
        {redirectRoutes.map((path) => (
          <Route key={path} path={path} element={<Navigate to={entryRoute} replace />} />
        ))}

        {/* Admin Routes */}
        <Route path="/clients/*" element={<ClientsRoutes />} />
        <Route path="/inbox" element={<InboxPage />} />

        {/* Client Routes */}
        <Route path="/reviews" element={<ReviewsPage />} />
        <Route path="/approved" element={<ReviewsPage />} />

        {/* Public Routes */}
        <Route path="/login" element={<Logout />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/ui-kit" element={<UIKitPage />} />

        {/* 404 Route */}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </>
  );
};

export default AuthenticatedApp;
