import React, { useEffect, useMemo, useReducer, useState } from "react";
import { Box, HStack, useDisclosure } from "@chakra-ui/react";
import * as zod from "zod";

import Button from "components/forms/button/button";
import Input from "components/forms/input/input";
import FormGroup from "components/forms/form-group/form-group";

import Form from "components/forms/form/form";
import FormErrors from "components/partials/form-errors/form-errors";
import Select from "components/forms/select/select";
import MultiSelect from "components/forms/multi-select-dropdown/multi-select-dropdown";
import ConfirmationModal from "components/modals/confirmation-modal/confirmation-modal";
import { createTouchpointActionType } from "containers/admin/clients/touchpoint/lp/lp-new-page/lp-new-page";

import { useCurrentCampaign } from "state/ducks";
import { useGetTouchpointContentTypesQuery } from "state/api/dictionary";

import { Unpersisted, WithMaybePersisted } from "models/model";
import Touchpoint, {
  creativeContextOptions,
  getTouchpointStatus,
  TouchpointAttributes,
} from "models/touchpoint";

import { useClientCampaigns } from "hooks/use-client-campaigns";

import {
  deeplyTransformEmptyStringToUndefined,
  getMultiSelectInitialValuesFromBoolean,
} from "utilities";

import { TouchpointType, TouchpointTypeLabel } from "types/touchpoint";
import { ZodFormErrors } from "types";

interface CreateLandingPageFormProps {
  landingPage?: Touchpoint;
  onSubmit: (
    attributes: Unpersisted<TouchpointAttributes>,
    actionType: createTouchpointActionType,
  ) => void;
  onCancel: () => void;
}

interface UpdateLandingPageFormProps {
  landingPage: Touchpoint;
  onSubmit: (attributes: TouchpointAttributes) => void;
  onCancel: () => void;
}

type LandingPageFormProps = CreateLandingPageFormProps | UpdateLandingPageFormProps;

function isUpdatingLandingPage(props: LandingPageFormProps): props is UpdateLandingPageFormProps {
  return (props as UpdateLandingPageFormProps).landingPage !== undefined;
}

const LandingPageForm = (props: LandingPageFormProps) => {
  const form = useLandingPageFormState(props.landingPage);
  const confirmCancelModal = useDisclosure();
  const currentCampaign = useCurrentCampaign();

  const { data: contentTypes = [] } = useGetTouchpointContentTypesQuery(
    currentCampaign.campaignObjective,
  );

  function handleContextMultiSelectChange(selectedOptions: any) {
    const possibleOptions = creativeContextOptions.map((option) => option.value);
    const excludedOptions = possibleOptions.filter((option) => {
      return !selectedOptions.find((innerOption: any) => innerOption.value === option);
    });
    excludedOptions.forEach((val: any) => {
      form.handleComplexChange(val, false);
    });
    selectedOptions.forEach((val: any) => {
      form.handleComplexChange(val.value, true);
    });
  }

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    // nativeEvent isn't a type in React.FormEvent even though IT SHOULD BE.
    // the actionType variable is used to identify the two types of submit buttons.
    //@ts-ignore
    let actionType = e.nativeEvent.submitter.value;

    try {
      const landingPageAttributes = schema.parse(
        deeplyTransformEmptyStringToUndefined({
          ...form.values,
        }),
      );
      form.setErrors({});

      const convertedLandingPage = {
        ...props.landingPage?.attributes,
        ...landingPageAttributes,
        status: getTouchpointStatus(landingPageAttributes.status),
        type: TouchpointType.LANDING_PAGE,
      };

      if (isUpdatingLandingPage(props)) {
        props.onSubmit(
          (function addBackIds(attributes: any): WithMaybePersisted<TouchpointAttributes, "id"> {
            attributes.id = props.landingPage.attributes.id;

            return attributes;
          })(convertedLandingPage),
        );
      } else {
        props.onSubmit(convertedLandingPage as TouchpointAttributes, actionType);
      }
    } catch (error) {
      if (error instanceof zod.ZodError) {
        form.setErrors(error.flatten().fieldErrors as ZodFormErrors);
      }
    }
  }

  useEffect(() => {
    if (contentTypes.length === 1 && !form.values.contentType) {
      form.handleComplexChange("contentType", contentTypes[0].name);
    }
  }, [contentTypes, form]);

  const isEditMode = !!props.landingPage;

  const campaignsRequestOptions = useMemo(() => ({ page: 0, size: 500 }), []);

  const clientCampaigns = useClientCampaigns(campaignsRequestOptions, {
    delayFetch: !isEditMode,
    trigger: isEditMode,
  });

  const isFormComplete = form.values.name && form.values.contentType;

  return (
    <Box data-testid="landing-page-form" mt={6}>
      {Object.entries(form.errors).length > 0 && <FormErrors form={form} />}

      <Form onSubmit={handleSubmit} width="full" data-testid="landing-page-edit-form">
        <div className="flex flex-col gap-y-8 max-w-xl">
          <FormGroup legend="Basic details">
            <Input
              isRequired
              isDisabled
              label="Touchpoint type"
              data-testid="touchpoint-type"
              defaultValue={TouchpointTypeLabel.LP_OTHER}
            />
            <Input
              isRequired
              label={`${TouchpointTypeLabel.LP_TOUCHPOINT} name`}
              data-testid="landing-page-edit-name"
              value={form.values.name}
              onChange={form.handleChange("name")}
            />
            {isEditMode && (
              <Select
                label="Associated campaign"
                value={form.values.campaignId}
                onChange={form.handleChange("campaignId")}>
                {clientCampaigns.map((campaign) => (
                  <option key={campaign.id} value={campaign.id}>
                    {campaign.name}
                  </option>
                ))}
              </Select>
            )}
          </FormGroup>
          <FormGroup legend="Content details">
            {contentTypes.length === 1 ? (
              <Select isRequired isDisabled label="Content type" value={contentTypes[0].name}>
                <option value={contentTypes[0].name}>{contentTypes[0].description}</option>
              </Select>
            ) : (
              <Select
                isRequired
                label="Content type"
                placeholder="Select program type"
                value={form.values.contentType}
                onChange={form.handleChange("contentType")}>
                {contentTypes.map((type) => (
                  <option key={type.name} value={type.name}>
                    {type.description}
                  </option>
                ))}
              </Select>
            )}
            <MultiSelect
              id="content-topic-dropdown"
              label="Context (optional)"
              placeholder="Select one or more contexts"
              options={creativeContextOptions}
              value={getMultiSelectInitialValuesFromBoolean(
                creativeContextOptions,
                creativeContextOptions.map((option) => {
                  return {
                    key: option.value,
                    // @ts-ignore
                    value: form.values[option.value],
                  };
                }),
              )}
              onChange={handleContextMultiSelectChange}
            />
          </FormGroup>
        </div>
        <div className="flex justify-between">
          <HStack spacing={2}>
            {!isEditMode && (
              <Button type="submit" value="toBuilder" isDisabled={!isFormComplete}>
                Continue to Page Builder
              </Button>
            )}

            <Button
              type="submit"
              value="toTouchpoint"
              isDisabled={!isFormComplete}
              variant={isEditMode ? "primary" : "secondary"}>
              {isEditMode ? "Save Changes" : "Save and Close"}
            </Button>

            <Button variant="link" onClick={confirmCancelModal.onOpen}>
              Cancel
            </Button>
          </HStack>
          {/* Delete button goes here */}
        </div>
      </Form>

      <ConfirmationModal
        {...confirmCancelModal}
        message="Are you sure you want to exit? All unsaved changes will be lost"
        cancelButtonText="No"
        confirmButtonText="Yes"
        onConfirm={props.onCancel}
        modalType="warning"
      />
    </Box>
  );
};

export default LandingPageForm;

const schema = zod.object({
  name: zod.string().optional(),
  campaignId: zod.string().optional(),
  contentType: zod.string().optional(),
  contentInteraction: zod.string().optional(),
  contextCampusLife: zod.boolean().optional(),
  contextFinancialAid: zod.boolean().optional(),
  contextVisit: zod.boolean().optional(),
  contextEducationOutcomes: zod.boolean().optional(),
  contextAcademics: zod.boolean().optional(),
  contextApply: zod.boolean().optional(),
  status: zod.string().optional(),
});

type LandingPageAttributesSchema = zod.infer<typeof schema>;
type LandingPageAttributesFormState = {
  contentType: string;
  contentInteraction: string;
  contextCampusLife: boolean;
  contextFinancialAid: boolean;
  contextVisit: boolean;
  contextEducationOutcomes: boolean;
  contextAcademics: boolean;
  contextApply: boolean;
  name: string;
  campaignId: string;
  status: string;
};
type LandingPageAttributesFormErrors = Partial<{
  [k in keyof LandingPageAttributesSchema]: string;
}>;

function initialLandingPageFormValues(landingPage?: Touchpoint): LandingPageAttributesFormState {
  // prettier-ignore
  return {
    name: landingPage?.name || '',
    campaignId: landingPage?.campaignId || '',
    contentType: landingPage?.contentType || '',
    contentInteraction: landingPage?.contentInteraction || '',
    contextCampusLife: landingPage?.contextCampusLife || false,
    contextFinancialAid: landingPage?.contextFinancialAid || false,
    contextVisit: landingPage?.contextVisit || false,
    contextEducationOutcomes: landingPage?.contextEducationOutcomes || false,
    contextAcademics: landingPage?.contextAcademics || false,
    contextApply: landingPage?.contextApply || false,
    status: landingPage?.attributes.status || '',
    }
}

function useLandingPageFormState(landingPage?: Touchpoint) {
  const [values, dispatch] = useReducer(
    function reducer(
      state: LandingPageAttributesFormState,
      action: { field: string; value: string | boolean | undefined },
    ) {
      return {
        ...state,
        [action.field]: action.value || (Array.isArray(action.value) ? [] : ""),
      };
    },
    landingPage,
    initialLandingPageFormValues,
  );

  function handleChange(field: string) {
    return function (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
      dispatch({ field, value: event.target.value });
    };
  }

  function handleComplexChange(field: string, value: any) {
    dispatch({ field, value });
  }

  const [errors, setErrors] = useState<LandingPageAttributesFormErrors>({});

  function handleErrors(errors: ZodFormErrors): void {
    const invalidFields = Object.keys(errors) as Array<keyof LandingPageAttributesSchema>;
    setErrors(
      invalidFields.reduce((memo, field) => {
        memo[field] = errors[field].join(", ");
        return memo;
      }, {} as LandingPageAttributesFormErrors),
    );
  }

  return { values, handleChange, handleComplexChange, errors, setErrors: handleErrors };
}
