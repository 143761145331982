import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

const VALID_FORMAT_MESSAGE = "Please provide 6 characters for a valid hex code";

const getRequiredHexInput = (inputName: string) =>
  z
    .string()
    .nonempty(`${inputName} color is required`)
    .regex(/^#[0-9A-Fa-f]{6}$/, VALID_FORMAT_MESSAGE);

const getOptionalHexInput = () =>
  z
    .string()
    .optional()
    .refine(
      (value) => value === undefined || value.length === 0 || /^#[0-9a-fA-F]{6}$/.test(value),
      {
        message: VALID_FORMAT_MESSAGE,
      },
    );

export const PREVIEW_FORM_DATA_VALIDATION_SCHEMA = zodResolver(
  z.object({
    colors: z.object({
      primary: getOptionalHexInput(),
      secondary: getOptionalHexInput(),
      tertiary: getOptionalHexInput(),
      intense: getRequiredHexInput("Intense"),
      bold: getRequiredHexInput("Bold"),
      soft: getRequiredHexInput("Soft"),
      subtle: getRequiredHexInput("Subtle"),
      white: getRequiredHexInput("White"),
    }),
  }),
);

export const PREVIEW_FORM_DEFAULT_VALUES = {
  colors: {
    primary: "",
    secondary: "",
    tertiary: "",
    intense: "#323B48",
    bold: "#5B6470",
    soft: "#858B93",
    subtle: "#E4E5E7",
    white: "#ffffff",
  },
};
