import ColorsSectionContent from "containers/admin/clients/client/theme-page/preview/sub-tabs/color/colors-section-content";

import {
  PreviewSidebarInputType,
  PreviewSidebarItemType,
} from "containers/admin/clients/client/theme-page/preview/preview-sidebar/preview-sidebar.types";

export const BRAND_COLORS: PreviewSidebarInputType[] = [
  { isRequired: false, name: "primary", label: "Primary color" },
  { isRequired: false, name: "secondary", label: "Secondary color" },
  { isRequired: false, name: "tertiary", label: "Tertiary color" },
];

export const NEUTRALS_COLORS: PreviewSidebarInputType[] = [
  { isRequired: true, name: "intense", label: "Intense" },
  { isRequired: true, name: "bold", label: "Bold" },
  { isRequired: true, name: "soft", label: "Soft" },
  { isRequired: true, name: "subtle", label: "Subtle" },
  { isRequired: true, name: "white", label: "White" },
];

export const COLOR_TAB_SIDEBAR: PreviewSidebarItemType[] = [
  {
    titleText: "BRAND",
    children: <ColorsSectionContent content={BRAND_COLORS} />,
  },
  {
    titleText: "NEUTRALS",
    children: <ColorsSectionContent content={NEUTRALS_COLORS} />,
  },
];
