import { env } from "env";

import SecondaryNav from "components/partials/secondary-nav/secondary-nav";

import { useCurrentClient } from "state/ducks/clients";

import { usePagePermission } from "hooks/use-page-permission";

import { Permission } from "types/auth";

const ClientSecondaryNav = () => {
  const client = useCurrentClient();

  usePagePermission(Permission.PERM_CLIENT_READ);

  const campaignsLink = { text: "Campaigns", to: `/clients/${client.id}/campaigns` };
  const themesLink = { text: "Theme", to: `/clients/${client.id}/theme` };
  const profileLink = { text: "Profile", to: `/clients/${client.id}/profile` };

  //TODO - remove condition after Theme feature is functionally implemented
  const navLinks =
    env.REACT_APP_ENV === "prod"
      ? [campaignsLink, profileLink]
      : [campaignsLink, themesLink, profileLink];

  return <SecondaryNav heading={client.name} links={navLinks} />;
};

export default ClientSecondaryNav;
