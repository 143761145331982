import { useNavigate } from "react-router-dom";

import SecondaryNav from "components/partials/secondary-nav/secondary-nav";
import { Span } from "components/partials/typography/typography";

import { useGetProgramTypesQuery, getDictionaryItemDescription } from "state/api/dictionary";
import { useCurrentCampaign } from "state/ducks/campaigns";
import { useCurrentClient } from "state/ducks/clients";
import { useCurrentUser } from "state/ducks";

import { hasPermission } from "utilities/user";

import { Permission } from "types/auth";

export const CampaignSecondaryNav = () => {
  const navigate = useNavigate();
  const client = useCurrentClient();
  const campaign = useCurrentCampaign();
  const currentUser = useCurrentUser();
  const { data: programTypes = [] } = useGetProgramTypesQuery();

  return (
    <SecondaryNav
      backButton={{
        show: !hasPermission(currentUser, Permission.PERM_CLIENT_USER),
        text: client.name,
        onClick: () => navigate(`/clients/${client.id}/campaigns`),
      }}
      heading={
        <>
          {campaign.name}
          <Span color="text.muted" ml={4}>
            {getDictionaryItemDescription(programTypes, campaign.programType)}
          </Span>
        </>
      }
    />
  );
};
