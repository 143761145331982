import { useNavigate } from "react-router-dom";

import PageContent from "components/partials/page-content/page-content";
import BasicInfo from "containers/admin/clients/client/components/basic-info/basic-info";
import toast from "components/partials/toast/toast";

import { Unpersisted } from "models/model";
import Client, { ClientAttributes } from "models/client";

import { getErrorMessage } from "utilities";
import { usePagePermission } from "hooks/use-page-permission";

import { Permission } from "types/auth";
import { H2 } from "components/partials/typography/typography";

export const AddClientPage = () => {
  const navigate = useNavigate();

  usePagePermission(Permission.PERM_CLIENT_WRITE);

  const submitNewClient = (attributes: Unpersisted<ClientAttributes>) => {
    Client.create(attributes)
      .then((client) => {
        toast.success({
          data: {
            title: "Client created",
            message:
              "Manage additional client profile from the sidebar on the left or create your first campaign",
          },
        });
        navigate(`/clients/${client.id}/profile`);
      })
      .catch((err) => {
        toast.error({
          data: {
            title: "Failed to create Client",
            message: getErrorMessage(err?.response?.data),
          },
        });
      });
  };

  const cancelCreateClient = () => {
    // Redirect to clients page
    navigate("/clients");
  };

  return (
    <PageContent data-testid="client-new-page">
      <H2 className="pt-6 mb-8">Add new client</H2>
      <BasicInfo
        onSubmit={submitNewClient}
        onCancel={cancelCreateClient}
        data-testid="client-add-submit-form"
      />
    </PageContent>
  );
};
