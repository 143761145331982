import { Navigate, Route, Routes } from "react-router-dom";

import Preview from "containers/admin/clients/client/theme-page/preview/preview";
import VersionHistory from "containers/admin/clients/client/theme-page/version-history/version-history";

import { Route as AppRoute } from "utilities/app-routes";

const ThemeRoutes = () => {
  return (
    <Routes>
      <Route index element={<Navigate to={AppRoute.preview} />} />

      <Route path={AppRoute.preview + "/*"} element={<Preview />} />
      <Route path={AppRoute.versions + "/*"} element={<VersionHistory />} />
    </Routes>
  );
};

export default ThemeRoutes;
