import React, { useEffect, useMemo, useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, HStack, useDisclosure } from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import * as zod from "zod";

import { Unpersisted, WithMaybePersisted } from "models/model";
import Touchpoint, {
  creativeContextOptions,
  getTouchpointStatus,
  TouchpointAttributes,
} from "models/touchpoint";

import Button from "components/forms/button/button";
import Input from "components/forms/input/input";
import FormGroup from "components/forms/form-group/form-group";
import Form from "components/forms/form/form";
import FormErrors from "components/partials/form-errors/form-errors";
import Select from "components/forms/select/select";
import MultiSelect from "components/forms/multi-select-dropdown/multi-select-dropdown";
import ConfirmationModal from "components/modals/confirmation-modal/confirmation-modal";
import toast from "components/partials/toast/toast";
import { createTouchpointActionType } from "containers/admin/clients/touchpoint/email/email-new-page/email-new-page";

import { useGetTouchpointContentTypesQuery } from "state/api/dictionary";
import { useCurrentCampaign, useCurrentClient, useCurrentUser } from "state/ducks";

import { useClientCampaigns } from "hooks/use-client-campaigns";

import {
  deeplyTransformEmptyStringToUndefined,
  getErrorMessage,
  getMultiSelectInitialValuesFromBoolean,
} from "utilities/index";
import { hasPermission } from "utilities/user";

import { Permission } from "types/auth";
import { TouchpointType } from "types/touchpoint";
import { ZodFormErrors } from "types";

interface CreateEmailFormProps {
  email?: Touchpoint;
  onSubmit: (
    attributes: Unpersisted<TouchpointAttributes>,
    actionType: createTouchpointActionType,
  ) => void;
  onCancel: () => void;
}

interface UpdateEmailFormProps {
  email: Touchpoint;
  onSubmit: (attributes: TouchpointAttributes) => void;
  onCancel: () => void;
}

type EmailFormProps = CreateEmailFormProps | UpdateEmailFormProps;

function isUpdatingEmail(props: EmailFormProps): props is UpdateEmailFormProps {
  return (props as UpdateEmailFormProps).email !== undefined;
}

const EmailForm = (props: EmailFormProps) => {
  const form = useEmailFormState(props.email);
  const currentCampaign = useCurrentCampaign();
  const currentClient = useCurrentClient();
  const confirmCancelModal = useDisclosure();
  const confirmDeleteModal = useDisclosure();
  const currentUser = useCurrentUser();
  const navigate = useNavigate();

  const { data: contentTypes = [] } = useGetTouchpointContentTypesQuery(
    currentCampaign.campaignObjective,
  );

  function handleContextMultiSelectChange(selectedOptions: any) {
    const possibleOptions = creativeContextOptions.map((option) => option.value);
    const excludedOptions = possibleOptions.filter((option) => {
      return !selectedOptions.find((innerOption: any) => innerOption.value === option);
    });
    excludedOptions.forEach((val: any) => {
      form.handleComplexChange(val, false);
    });
    selectedOptions.forEach((val: any) => {
      form.handleComplexChange(val.value, true);
    });
  }

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    // the actionType variable is used to identify the two types of submit buttons.
    //@ts-ignore
    let actionType = e.nativeEvent.submitter.value;

    try {
      const emailAttributes = schema.parse(
        deeplyTransformEmptyStringToUndefined({
          ...form.values,
        }),
      );
      form.setErrors({});

      const convertedEmail = {
        ...props.email?.attributes,
        ...emailAttributes,
        status: getTouchpointStatus(emailAttributes.status),
        type: TouchpointType.EMAIL,
      };

      if (isUpdatingEmail(props)) {
        props.onSubmit(
          (function addBackIds(attributes: any): WithMaybePersisted<TouchpointAttributes, "id"> {
            attributes.id = props.email.attributes.id;

            return attributes;
          })(convertedEmail),
        );
      } else {
        // this runs when not in edit mode in email-new-page.tsx
        props.onSubmit(convertedEmail as TouchpointAttributes, actionType);
      }
    } catch (error) {
      if (error instanceof zod.ZodError) {
        form.setErrors(error.flatten().fieldErrors as ZodFormErrors);
      }
    }
  }

  function handleDeleteEmail() {
    if (!props.email) {
      return;
    }
    Touchpoint.delete({
      id: props.email.id,
      campaignId: currentCampaign.id,
      clientId: currentClient.id,
    })
      .then(() => {
        toast.success({
          data: {
            title: "Email deleted",
          },
        });
        navigate("..");
      })
      .catch((err) => {
        toast.error({
          data: {
            title: "Failed to delete email",
            message: getErrorMessage(err?.response?.data),
          },
        });
      });
    confirmDeleteModal.onClose();
  }

  useEffect(() => {
    if (contentTypes.length === 1 && !form.values.contentType) {
      form.handleComplexChange("contentType", contentTypes[0].name);
    }
  }, [contentTypes, form]);

  const isEditMode = !!props.email;

  const campaignsRequestOptions = useMemo(() => ({ page: 0, size: 500 }), []);

  const clientCampaigns = useClientCampaigns(campaignsRequestOptions, {
    delayFetch: !isEditMode,
    trigger: isEditMode,
  });

  const isFormComplete = form.values.name && form.values.contentType;

  return (
    <Box data-testid="email-form" mt={6}>
      {Object.entries(form.errors).length > 0 && <FormErrors form={form} />}

      <Form onSubmit={handleSubmit} width="full" data-testid="email-edit-form">
        <div className="flex flex-col gap-y-8 max-w-xl">
          <FormGroup legend="Basic details">
            <Input isRequired isDisabled label="Touchpoint type" defaultValue="Email" />
            <Input
              isRequired
              label="Email name"
              value={form.values.name}
              onChange={form.handleChange("name")}
            />
            {isEditMode && (
              <Select
                label="Associated campaign"
                value={form.values.campaignId}
                onChange={form.handleChange("campaignId")}>
                {clientCampaigns.map((campaign) => (
                  <option key={campaign.id} value={campaign.id}>
                    {campaign.name}
                  </option>
                ))}
              </Select>
            )}
          </FormGroup>

          <FormGroup legend="Content details">
            {contentTypes.length === 1 ? (
              <Select isRequired isDisabled label="Content type" value={contentTypes[0].name}>
                <option value={contentTypes[0].name}>{contentTypes[0].description}</option>
              </Select>
            ) : (
              <Select
                isRequired
                label="Content type"
                placeholder="Select program type"
                value={form.values.contentType}
                onChange={form.handleChange("contentType")}>
                {contentTypes.map((type) => (
                  <option key={type.name} value={type.name}>
                    {type.description}
                  </option>
                ))}
              </Select>
            )}

            <MultiSelect
              id="content-topic-dropdown"
              label="Contexts (optional)"
              placeholder="Select one or more contexts"
              options={creativeContextOptions}
              value={getMultiSelectInitialValuesFromBoolean(
                creativeContextOptions,
                creativeContextOptions.map((option) => {
                  return {
                    key: option.value,
                    // @ts-ignore
                    value: form.values[option.value],
                  };
                }),
              )}
              onChange={handleContextMultiSelectChange}
            />
          </FormGroup>
        </div>
        <div className="flex justify-between">
          <HStack spacing={2}>
            {!isEditMode && (
              <Button type="submit" value="toBuilder" isDisabled={!isFormComplete}>
                Continue to Email Builder
              </Button>
            )}
            <Button
              type="submit"
              value="toTouchpoint"
              isDisabled={!isFormComplete}
              variant={isEditMode ? "primary" : "secondary"}>
              {isEditMode ? "Save Changes" : "Save and Close"}
            </Button>
            <Button variant="link" onClick={confirmCancelModal.onOpen}>
              Cancel
            </Button>
          </HStack>
          {props.email && hasPermission(currentUser, Permission.PERM_TOUCHPOINT_WRITE) && (
            <Button
              variant="link"
              leftIcon={<DeleteIcon fontSize="large" />}
              onClick={confirmDeleteModal.onOpen}
              data-testid="delete-button">
              Delete
            </Button>
          )}
        </div>
      </Form>

      <ConfirmationModal
        {...confirmCancelModal}
        message="Are you sure you want to exit? All unsaved changes will be lost"
        cancelButtonText="No"
        confirmButtonText="Yes"
        onConfirm={props.onCancel}
        modalType="warning"
      />
      <ConfirmationModal
        {...confirmDeleteModal}
        headline="Are you sure you want to delete this email?"
        message={""}
        confirmButtonText="Delete email"
        onConfirm={handleDeleteEmail}
        modalType="danger"
      />
    </Box>
  );
};

export default EmailForm;

const schema = zod.object({
  assigneeId: zod.string().optional(),
  clientId: zod.string().optional(),
  contentType: zod.string().optional(),
  contentInteraction: zod.string().optional(),
  contextCampusLife: zod.boolean().optional(),
  contextFinancialAid: zod.boolean().optional(),
  contextVisit: zod.boolean().optional(),
  contextEducationOutcomes: zod.boolean().optional(),
  contextAcademics: zod.boolean().optional(),
  contextApply: zod.boolean().optional(),
  contextDeposit: zod.boolean().optional(),
  contextNA: zod.boolean().optional(),
  htmlContentId: zod.string().optional(),
  name: zod.string().optional(),
  campaignId: zod.string().optional(),
  status: zod.string().optional(),
  themeId: zod.string().optional(),
  type: zod.string().optional(),
});
type EmailAttributesSchema = zod.infer<typeof schema>;
type EmailAttributesFormState = {
  assigneeId: string;
  clientId: string;
  contentType: string;
  contentInteraction: string;
  contextCampusLife: boolean;
  contextFinancialAid: boolean;
  contextVisit: boolean;
  contextEducationOutcomes: boolean;
  contextAcademics: boolean;
  contextApply: boolean;
  contextDeposit: boolean;
  contextNA: boolean;
  name: string;
  campaignId: string;
  status: string;
  type: "Email";
};
type EmailAttributesFormErrors = Partial<{ [k in keyof EmailAttributesSchema]: string }>;

function initialEmailFormValues(email?: Touchpoint): EmailAttributesFormState {
  // prettier-ignore
  return {
    assigneeId: email?.attributes.assigneeId || '',
    campaignId: email?.attributes.campaignId || '',
    status: email?.attributes.status || '',
    clientId: email?.attributes.clientId || '',
    contentType: email?.attributes.contentType || '',
    contentInteraction: email?.attributes.contentInteraction || '',
    contextCampusLife: email?.attributes.contextCampusLife || false,
    contextFinancialAid: email?.attributes.contextFinancialAid || false,
    contextVisit: email?.attributes.contextVisit || false,
    contextEducationOutcomes: email?.attributes.contextEducationOutcomes || false,
    contextAcademics: email?.attributes.contextAcademics || false,
    contextApply: email?.attributes.contextApply || false,
    contextDeposit: email?.attributes.contextDeposit || false,
    contextNA: email?.attributes.contextNA || false,
    name: email?.attributes.name || '',
    type: "Email",
    }
}

function useEmailFormState(email?: Touchpoint) {
  const [values, dispatch] = useReducer(
    function reducer(
      state: EmailAttributesFormState,
      action: { field: string; value: string | boolean | undefined },
    ) {
      return {
        ...state,
        [action.field]: action.value || (Array.isArray(action.value) ? [] : ""),
      };
    },
    email,
    initialEmailFormValues,
  );

  function handleChange(field: string) {
    return function (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
      dispatch({ field, value: event.target.value });
    };
  }

  function handleComplexChange(field: string, value: any) {
    dispatch({ field, value });
  }

  const [errors, setErrors] = useState<EmailAttributesFormErrors>({});

  function handleErrors(errors: ZodFormErrors): void {
    const invalidFields = Object.keys(errors) as Array<keyof EmailAttributesSchema>;
    setErrors(
      invalidFields.reduce((memo, field) => {
        memo[field] = errors[field].join(", ");
        return memo;
      }, {} as EmailAttributesFormErrors),
    );
  }

  return { values, handleChange, handleComplexChange, errors, setErrors: handleErrors };
}
