export enum Route {
  dashboard = "/dashboard",
  clients = "/clients",
  inbox = "/inbox",
  reviews = "/reviews",
  approved = "/approved",
  admin = "/admin",
  new = "new",
  edit = "edit",
  campaigns = "campaigns",
  settings = "settings",
  creative = "creative",
  profile = "profile",
  assets = "assets",
  theme = "theme",
  notFound = "/404",
  uiKit = "/ui-kit",
  emails = "emails",
  landingPages = "landing-pages",
  emptyJourneyNodes = "empty-journey-nodes",
  emailBuilder = "email-builder",
  emailPreview = "email-preview",
  lpBuilder = "landing-page-builder",
  lpPreview = "landing-page-preview",
  login = "/login",
  logout = "/logout",
  versions = "versions",
  themeBuilder = "theme-builder",
  themePreview = "theme-preview",
  details = "details",
  preview = "preview",
  basic = "basic",
  team = "team-members",
  emailTypes = "email-types",
  design = "design",
}

export enum PreviewRoute {
  color = "/colors",
  fonts = "/fonts",
  typography = "/typography",
  button = "/button",
}
