import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Box, HStack } from "@chakra-ui/react";

import LandingPageForm from "containers/admin/clients/touchpoint/components/lp-form/lp-form";
import { H2, H5 } from "components/partials/typography/typography";
import PageContentHeader from "components/partials/page-content-header/page-content-header";
import Breadcrumbs from "components/new/beadcrumbs/breadcrumbs";
import toast from "components/partials/toast/toast";
import { useCurrentClient, setCurrentTouchpoint, useCurrentTouchpoint } from "state/ducks";
import { useCurrentCampaign, setCurrentCampaign } from "state/ducks/campaigns";
import { Touchpoint } from "models";
import { TouchpointAttributes } from "models/touchpoint";

import { getErrorMessage } from "utilities";
import { usePagePermission } from "hooks/use-page-permission";

import { Permission } from "types/auth";
import { TouchpointTypeLabel } from "types/touchpoint";

const LPEditPage = () => {
  const navigate = useNavigate();
  const currentClient = useCurrentClient();
  const currentCampaign = useCurrentCampaign();
  const params = useParams();
  const currentTouchpoint = useCurrentTouchpoint();
  const dispatch = useDispatch();

  usePagePermission(Permission.PERM_TOUCHPOINT_READ);

  const updateLP = (attributes: TouchpointAttributes) => {
    const metaData = {
      ...currentTouchpoint.attributes,
      contentInteraction: attributes.contentInteraction,
      contextAcademics: attributes.contextAcademics,
      contextApply: attributes.contextApply,
      contextCampusLife: attributes.contextCampusLife,
      contextEducationOutcomes: attributes.contextEducationOutcomes,
      contextFinancialAid: attributes.contextFinancialAid,
      contextVisit: attributes.contextVisit,
      contentType: attributes.contentType,
      description: attributes.description,
      name: attributes.name,
      id: attributes.id,
      campaignId: attributes.campaignId,
    };

    Touchpoint.replace({
      clientId: currentClient.id,
      campaignId: currentCampaign.id,
      data: metaData,
      id: attributes.id,
    })
      .then((updatedTouchpoint) => {
        dispatch(setCurrentTouchpoint(updatedTouchpoint.attributes));

        toast.success({
          data: {
            title: `${TouchpointTypeLabel.LP_TOUCHPOINT} updated`,
          },
        });

        if (currentCampaign.id !== attributes.campaignId) {
          const updatedCampaign = { ...currentCampaign.attributes, id: attributes.campaignId };
          dispatch(setCurrentCampaign(updatedCampaign));

          // Navigate to new campaign path
          navigate(
            `/clients/${currentClient.id}/campaigns/${attributes.campaignId}/creative/${currentTouchpoint.id}/details`,
          );
        } else {
          navigate("../details");
        }
      })
      .catch((err) => {
        toast.error({
          data: {
            title: `Failed to edit ${TouchpointTypeLabel.LP_TOUCHPOINT}`,
            message: getErrorMessage(err?.response?.data),
          },
        });
      });
  };

  const cancelEditLP = () => {
    navigate("../details");
  };

  return (
    <Box>
      <Breadcrumbs
        breadcrumbs={[
          { label: currentClient?.name, href: `/clients/${params?.clientId}/profile` },
          {
            label: currentCampaign?.name,
            href: `/clients/${params?.clientId}/campaigns/${params?.campaignId}/creative`,
          },
          {
            label: currentTouchpoint?.name || "Creative name",
            href: `/clients/${params?.clientId}/campaigns/${params?.campaignId}/creative/${params?.touchpointId}/preview`,
          },
        ]}
      />
      <PageContentHeader>
        <HStack className="pt-4 space-x-3" align="baseline">
          <H2>Edit {TouchpointTypeLabel.LP_TOUCHPOINT}</H2>
          <H5>{currentTouchpoint?.name}</H5>
        </HStack>
      </PageContentHeader>
      <LandingPageForm
        landingPage={currentTouchpoint}
        onSubmit={updateLP}
        onCancel={cancelEditLP}
      />
    </Box>
  );
};

export default LPEditPage;
