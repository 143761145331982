import { useNavigate } from "react-router-dom";
import { MdEmail, MdModeComment } from "react-icons/md";
import { RiLayout5Fill } from "react-icons/ri";
import { Icon } from "@chakra-ui/react";
import { AiFillFlag } from "react-icons/ai";

import { H3 } from "components/partials/typography/typography";
import StatusIndicator from "components/partials/status-indicator/status-indicator";
import {
  useGetAudienceTypesQuery,
  useGetTouchpointVersionStatusesQuery,
  getDictionaryItemDescription,
  type TouchpointStatusDictionaryItem,
} from "state/api/dictionary";
import { useCurrentUser } from "state/ducks";
import { TouchpointType } from "types/touchpoint";
import { InboxItem } from "types/inbox";
import { PublishedLabel } from "components/partials/published-label/published-label";
import { TableCell, TableCellProps } from "components/table/table-cell/table-cell";
import Link from "components/partials/link/link";
import { ContentStatus, DEFAULT_CLIENT_REVIEW_STATUSES } from "types";

export interface InboxTableColumnProps extends TableCellProps {
  item: InboxItem;
}

export interface TableStatusColumnProps extends TableCellProps {
  clientVersionStatuses: TouchpointStatusDictionaryItem;
}

export const CreativeNameColumn = ({
  item,
  isClientUser,
}: InboxTableColumnProps & { isClientUser: boolean }) => {
  const currentUser = useCurrentUser();
  const navigate = useNavigate();

  const clientId = isClientUser ? currentUser.clients[0].id : item?.clientId;

  const campaignLink = `/clients/${clientId}/campaigns/${item?.campaignId}/creative`;

  const creativeLink = `${campaignLink}/${item?.touchpointId}/preview`;

  return (
    <TableCell>
      <div className="flex">
        <Icon
          as={item?.touchpointType === TouchpointType.EMAIL ? MdEmail : RiLayout5Fill}
          fontSize="md"
          color="muted"
          mr={2}
          data-testid={item?.touchpointType + "-type"}
        />
        <div className="flex flex-col gap-1">
          <H3
            data-testid="creative-link"
            cursor="pointer"
            lineHeight="base"
            fontSize="sm"
            color="text.link"
            onClick={() => navigate(creativeLink)}>
            {item?.touchpointName}
          </H3>
          <div className="flex">
            <span className="whitespace-nowrap">v{item?.touchpointVersionVersion}</span>
            {!isClientUser && (
              <>
                <span className="px-1">•</span>
                <Link data-testid="campaign-link" onClick={() => navigate(campaignLink)}>
                  {item?.campaignName}
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
    </TableCell>
  );
};

export const ClientNameColumn = ({ clientName }: { clientName?: string }) => (
  <TableCell>
    <span className="whitespace-nowrap">{clientName}</span>
  </TableCell>
);

export const CampaignNameColumn = ({ campaignName }: { campaignName?: string }) => (
  <TableCell>{campaignName}</TableCell>
);

export const LastUpdatedColumn = ({ lastModifiedDate }: { lastModifiedDate: string }) => {
  const formattedDate = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  }).format(new Date(lastModifiedDate));

  const formattedTime = new Intl.DateTimeFormat("en-US", {
    hour: "numeric",
    minute: "numeric",
  }).format(new Date(lastModifiedDate));

  return (
    <TableCell>
      <div className="flex gap-1">
        <span className="whitespace-nowrap">{formattedDate},</span>
        <span className="whitespace-nowrap">{formattedTime}</span>
      </div>
    </TableCell>
  );
};

export const ClientReviewStatusColumn = ({ status }: { status?: ContentStatus }) => {
  const { data: touchpointVersionStatuses = [] } = useGetTouchpointVersionStatusesQuery();

  const clientVersionStatuses =
    touchpointVersionStatuses.find((el) => el.status === status) || DEFAULT_CLIENT_REVIEW_STATUSES;

  return (
    <TableCell>
      {!!clientVersionStatuses?.status && clientVersionStatuses?.clientReviewStatusName && (
        <StatusIndicator clientVersionStatuses={clientVersionStatuses} />
      )}
    </TableCell>
  );
};

export const PublishedStateColumn = ({ status }: { status?: ContentStatus }) => {
  const { data: touchpointVersionStatuses = [] } = useGetTouchpointVersionStatusesQuery();

  const clientVersionStatuses =
    touchpointVersionStatuses.find((el) => el.status === status) || DEFAULT_CLIENT_REVIEW_STATUSES;

  return (
    <TableCell>{clientVersionStatuses?.status === "PUBLISHED" && <PublishedLabel />}</TableCell>
  );
};

export const CommentsColumn = ({ item }: InboxTableColumnProps) => (
  <TableCell>
    <div className="flex justify-center">
      {!!item?.openCommentCount && (
        <div className="flex items-center text-aqua-dark mr-3">
          <MdModeComment size={15} />
          <span className="ml-1">{item?.openCommentCount}</span>
        </div>
      )}
      {!!item?.openActionCommentCount && (
        <div className="flex items-center text-scarlett-dark">
          <AiFillFlag size={15} />
          <span className="ml-1">{item?.openActionCommentCount}</span>
        </div>
      )}
    </div>
  </TableCell>
);

export const AudienceColumn = ({ audiences = [] }: { audiences: string[] }) => {
  const { data: audienceDictionaryItems = [], isLoading } = useGetAudienceTypesQuery();

  const AudienceTableCell = ({ children }: React.PropsWithChildren) => (
    <TableCell>
      <div className="flex flex-col items-start gap-1">{children}</div>
    </TableCell>
  );

  if (isLoading) {
    return (
      <AudienceTableCell>
        <span className="whitespace-nowrap">Loading...</span>
      </AudienceTableCell>
    );
  }

  const audienceDescriptions = audiences.map((audienceName) => ({
    name: audienceName,
    description: getDictionaryItemDescription(audienceDictionaryItems, audienceName),
  }));

  return (
    <AudienceTableCell>
      {audienceDescriptions.map(({ name, description }) => (
        <span key={name} className="whitespace-nowrap">
          {description}
        </span>
      ))}
    </AudienceTableCell>
  );
};
