import PreviewSidebar from "containers/admin/clients/client/theme-page/preview/preview-sidebar/preview-sidebar";
import PreviewArea from "containers/admin/clients/client/theme-page/preview/preview-area/preview-area";

import { BUTTON_TAB_SIDEBAR } from "containers/admin/clients/client/theme-page/preview/sub-tabs/button/button.constants";

const Button = () => {
  return (
    <div className="border-t border-zinc-200 flex w-full overflow-y-auto flex-1">
      <PreviewSidebar sidebarItems={BUTTON_TAB_SIDEBAR} />
      <PreviewArea />
    </div>
  );
};

export default Button;
